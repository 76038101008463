<template>
<div class="flex flex-column flex-auto">
    <div class="p-5">
        <Accordion class="w-full" :active-index="-1">
            <!-- Login Information -->
            <AccordionTab header="User Login Info">
                <!-- Content -->
                <div class="col-12">
                    <DataTable :value="userLoginInfo" :loading="userInfoLoading" class="w-full" striped-rows
                               paginator :rows="10" :rowsPerPageOptions="[10, 100, 1000]">
                        <Column field="UTC_EntryDate" header="Timestamp" ></Column>
                        <Column field="Username" header="Username" ></Column>
                    </DataTable>
                </div>
            </AccordionTab>

            <!-- Asset Information -->
            <AccordionTab header="Asset Info">
                <!-- Content -->
                <div class="col-12">
                    <DataTable :value="fullOverview" :loading="assetInfoLoading" class="w-full" striped-rows>
                        <Column field="assetid" header="Asset Id" ></Column>
                        <Column field="assetname" header="Asset Name" ></Column>
                        <Column field="total" header="Total Links" ></Column>
                        <Column field="filtered" header="Filtered Links" ></Column>
                        <Column field="results" header="Successful Links" ></Column>
                    </DataTable>
                </div>
            </AccordionTab>

            <!-- Preinject Domain Info -->
            <AccordionTab header="Preinject Info">
                <!-- Content -->
                <div class="col-12">
                    <DataTable :value="preinjectInfo" :loading="preinjectInfoLoading" class="w-full" striped-rows>
                        <Column field="date" header="Date" ></Column>
                        <Column field="Verifier" header="Verifier" ></Column>
                        <Column field="count" header="Total Links" ></Column>
                    </DataTable>
                </div>
            </AccordionTab>
        </Accordion>

        <div class="surface-card shadow-2 border-round p-3 mb-3">
            <apexchart type="rangeBar" height="350" :options="chartOptions" :series="sessionSeries"></apexchart>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "Analytics",

    data() {
        return {
            userInfoLoading: false,
            userLoginInfo: [],

            assetInfoLoading: false,
            fullOverview: [],

            sessionInfoLoading: false,
            sessionInfo: [],

            preinjectInfoLoading: false,
            preinjectInfo: [],

            chartOptions: {
                chart: {height: 350,type: 'rangeBar'},plotOptions: {bar: {horizontal: true,barHeight: '25%',rangeBarGroupRows: true}},fill: {type: 'gradient',opacity: 0.6,}, xaxis: {type: 'datetime'},legend: {position: 'top'},
                tooltip: {custom: function(opts) {const from = new Date(opts.y1).toLocaleString();const to = new Date(opts.y2).toLocaleString();return `<div class="arrow_box"> <span>From: ${from}</span> <span>To: ${to}</span> </div>`;}}
            },
        }
    },

    computed: {
        sessionSeries() {
            let sessionSeries = [];
            this.sessionInfo.forEach((entry) => {
                if (sessionSeries.some((series) => series.name === entry.Source)) {
                    let index = sessionSeries.findIndex((series) => series.name === entry.Source);
                    sessionSeries[index].data.push({
                        x: entry.Username,
                        y: [
                            new Date(entry.start).getTime(),
                            new Date(entry.end).getTime()
                        ]
                    });
                } else {
                    sessionSeries.push({
                        name: entry.Source,
                        data: [
                            {
                                x: entry.Username,
                                y: [
                                    new Date(entry.start).getTime(),
                                    new Date(entry.end).getTime()
                                ]
                            }
                        ]
                    });
                }
            });
            return sessionSeries;
        }
    },

    mounted() {
        this.getUserLoginInfo();
        this.getFullOverview();
        this.getSessionInfo();
        this.getPreinjectInfo();
    },

    methods: {
        getUserLoginInfo() {
            this.userInfoLoading = true;
            this.$factory.adminPage.getLoginInfo()
                .then((response) => {
                    // Convert UTC_EntryDate to local time
                    response.forEach((entry) => {
                        entry.UTC_EntryDate = new Date(entry.UTC_EntryDate + "Z").toLocaleString();
                    });

                    this.userLoginInfo = response;
                    console.debug(this.userLoginInfo);
                }).catch((error) => {
                    this.$emitter.emit('bad-toast', 'Could not get user login info.');
                    console.log(error);
                }).finally(() => {
                    this.userInfoLoading = false;
                });
        },

        getFullOverview() {
            this.assetInfoLoading = true;
            this.$factory.analytics.getTotalOverview().then((response) => {
                this.fullOverview = response;
                console.debug(this.fullOverview);
            }).catch((error) => {
                this.$emitter.emit('bad-toast', 'Could not get full overview.');
                console.log(error);
            }).finally(() => {
                this.assetInfoLoading = false;
            });
        },

        getSessionInfo() {
            this.sessionInfoLoading = true;
            this.$factory.analytics.getSessionInfo().then((response) => {
                this.sessionInfo = response;
                console.debug(this.sessionInfo);
            }).catch((error) => {
                this.$emitter.emit('bad-toast', 'Could not get session info.');
                console.log(error);
            }).finally(() => {
                this.sessionInfoLoading = false;
            });
        },

        getPreinjectInfo() {
            this.preinjectInfoLoading = true;
            this.$factory.analytics.getPreinjectDomainInfo().then((response) => {
                this.preinjectInfo = response;
                console.debug(this.preinjectInfo);
            }).catch((error) => {
                this.$emitter.emit('bad-toast', 'Could not get preinject info.');
                console.log(error);
            }).finally(() => {
                this.preinjectInfoLoading = false;
            });
        },

    }
}
</script>

<style scoped>

</style>
